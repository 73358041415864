<template>
  <v-container fluid style="height: 100%;"  class="d-flex justify-center align-center">
    <v-row class="d-flex flex-column align-center">
      <span class="title-style">404</span>
      <img src="../../assets/sorry.webp" class="image-ups-style"/>
      <span class="text-h5">Sorry, page not found</span>
      <v-btn class="mt-4" dark color="blue-grey " @click="goHome">
        <v-icon left>mdi-home-circle</v-icon>
        Go Home
      </v-btn>
    </v-row>+
  </v-container>
</template>
<script>
export default {
  name: "notFoundBody",
  methods: {
			goHome() {
				this.$router.push({ name: "Dashboard"});
			},
		},
};
</script>
<style scoped>
.image-ups-style {
  max-height: 400px;
}
@media (max-width: 800px) {
  .image-ups-style {
    max-height: 300px;
  }
}
.title-style {
    font-size: 150px;
    line-height: 150px;
    font-weight: 700;
    color: #252932;
    text-shadow: rgba(61, 61, 61, 0.3) 1px 1px, rgba(61, 61, 61, 0.2) 2px 2px,
      rgba(61, 61, 61, 0.3) 3px 3px;
  }
</style>
