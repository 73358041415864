<template>
  <div style="height: 100vh;">
    <NotFoundBody />
  </div>  
</template>

<script>
import NotFoundBody from '../components/errors/404-body.vue';

export default {
  name: '404PageView',
  components: {
    NotFoundBody
  }
}
</script>
